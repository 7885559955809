import React from "react";
import { Container, Row, Col } from "react-bootstrap";


export default function Footer(props) {
  const { year, setTerm } = props;

  return (<>
  
    <footer className="font-white">
      <Container>
        <Row className="footer-row">
          <Col xs="auto">
            <a href="/">
              {`© ${year} New College Communities. `}
            </a>
            {`All rights reserved. | `}
            <a onClick={() => setTerm(true)}>
              {"Terms And Conditions"}
            </a>
            <span> | </span>
            <a href="/esafety" target="_blank" className="esafety-link">{"eSafety"}</a>
          </Col>
        </Row>
        <Row className="footer-row-sm">
          <Col className="small-footer" xs="auto">
            <a href="/">
              {`© ${year} New College Communities. `}
            </a>
            {`All rights reserved. `} <br/>
            <a onClick={() => setTerm(true)}>
              {"Terms And Conditions"}
            </a>
            <div>
              <a href="/esafety" target="_blank" className="esafety-link">{"eSafety"}</a>
            </div>
          </Col>
        </Row>
        <Row className="footer-row-sm-extra">
          <Col className="small-footer" xs="auto">
            <a href="/">
              {`© ${year} New College Communities. `}
            </a> <br/>
            {`All rights reserved. `} <br/>
            <a onClick={() => setTerm(true)}>
              {"Terms And Conditions"}
            </a>
            <div>
              <a href="/esafety" target="_blank" className="esafety-link">{"eSafety"}</a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  </>);
}